var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"px-14"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Product Name")]),_c('v-text-field',{attrs:{"placeholder":"","outlined":"","label":"","hide-details":"auto","background-color":"white","rules":[
              function (v) { return !!v || 'Product name is required'; },
              function (v) { return v.length <= 300 || 'name must be less than 300 characters'; } ]},model:{value:(_vm.payment.name),callback:function ($$v) {_vm.$set(_vm.payment, "name", $$v)},expression:"payment.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Due Date")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"auto","background-color":"white","label":"","append-icon":"mdi-calendar-blank-outline","outlined":"","rules":[function (v) { return !!v || 'Due Date is required'; }],"readonly":"","value":_vm.dueDateIndays}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.minDate},model:{value:(_vm.due_date),callback:function ($$v) {_vm.due_date=$$v},expression:"due_date"}})],1)],1),_c('v-col',[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Amount to collect")]),_c('v-text-field',{attrs:{"outlined":"","label":"","hide-details":"auto","background-color":"white","rules":_vm.amountRule},on:{"blur":_vm.formatAmount},model:{value:(_vm.payment.amount),callback:function ($$v) {_vm.$set(_vm.payment, "amount", $$v)},expression:"payment.amount"}})],1)],1),_c('div',{staticClass:"mt-6"},[_c('v-textarea',{attrs:{"outlined":"","background-color":"white","hide-details":"auto","placeholder":"payment description","rules":[
            function (v) { return !!v || 'Payment description is required'; },
            function (v) { return v.length <= 600 ||
              'Form description must be less than 600 characters'; } ]},model:{value:(_vm.payment.description),callback:function ($$v) {_vm.$set(_vm.payment, "description", $$v)},expression:"payment.description"}})],1),_c('div',{staticClass:"d-flex align-center",attrs:{"id":"switch__sect"}},[_c('v-switch',{attrs:{"dense":"","true-value":"1","false-value":"0","hide-details":"auto","label":"Include VAT"},model:{value:(_vm.payment.tax),callback:function ($$v) {_vm.$set(_vm.payment, "tax", $$v)},expression:"payment.tax"}})],1)],1),_c('DialogActionBtns',{staticClass:"pr-0",attrs:{"btnText":"save","loading":_vm.loading},on:{"clickedCancel":function($event){return _vm.$emit('cancel')},"click":_vm.savePayment}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }