<template>
  <div>
    <v-dialog
      elevation="0"
      v-model="dialog"
      max-width="590"
      overlay-color="#19283d"
      overlay-opacity="0.282397"
    >
      <template v-if="!isEdit" v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          class="text-capitalize"
          style="
            background: var(--v-primary-base);
            box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
            border-radius: 4px;
          "
          :style="{
            width: `${$vuetify.breakpoint.smAndDown ? '120px' : '209px'}`,
            height: `${$vuetify.breakpoint.smAndDown ? '44px' : '54px'}`,
          }"
        >
          <img :src="require('@/assets/pbot_icons/file.svg')" />
          <span class="btn-text"> New Form </span>
        </v-btn>
      </template>
      <v-card
        max-width=""
        flat
        class="m-0"
        style="background: #f8f7f4; border-radius: 8px"
      >
        <v-card-title
          style="
            background: #ffffff;
            border-radius: 8px 8px 0px 0px;
            height: 54px;
          "
        >
          <img
            :src="require('@/assets/icons/task-square.png')"
            width="20"
            height="20"
          />
          <span class="ml-5 modal__title">
            {{
              !isEdit ? " Create form " : `Form: ${formData.form_title || ""}`
            }}</span
          >
          <v-spacer></v-spacer>
          <v-icon
            tag="button"
            @click="closeFormDialog"
            class="text-bolder"
            color="#596A73"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <template>
          <v-tabs
            slider-size="4"
            background-color="#f3eee8"
            v-model="tab"
            align-with-title
            style="border-bottom: 1px solid #d9dee1"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab href="#newform">
              {{ isEdit ? "Details" : "New Form" }}</v-tab
            >
            <v-tab href="#payment" v-if="showPaymentTab"> payment </v-tab>
            <v-tab href="#apiParams" v-if="showApi"> Api Params </v-tab>
            <v-tab href="#embed" v-if="isEdit"> Embed </v-tab>
          </v-tabs>
        </template>
        <v-tabs-items v-model="tab" style="background-color: #f8f7f4">
          <v-tab-item :key="1" value="newform" class="mt-8">
            <!-- Create form fields components  -->
            <CreateFormFields
              :isEdit="isEdit"
              @cancel="dialog = false"
              @next="switchTab"
              v-model="form.details"
              ref="formFields"
            />
          </v-tab-item>
          <v-tab-item :key="2" value="payment" class="mt-8">
            <!-- payment form fields components  -->
            <CreateFormPaymentTab
              :isEdit="isEdit"
              @cancel="dialog = false"
              @next="switchTab"
              v-model="form.paymentData"
            />
          </v-tab-item>
          <v-tab-item :key="3" value="apiParams" class="mt-8">
            <!-- payment form fields components  -->
            <apiParamsTab
              :isEdit="isEdit"
              @cancel="dialog = false"
              @next="switchTab"
              v-model="form.apiData"
            />
          </v-tab-item>
          <v-tab-item :key="4" value="embed" class="mt-10">
            <EmbedFormFields
              v-if="form.details && form.details.hypn_id"
              :hypn_id="form.details.hypn_id"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreateFormFields from "./createFormFields.vue";
import CreateFormPaymentTab from "./createFormPaymentTab.vue";
import apiParamsTab from "./apiParamsTab.vue";
import { mapGetters } from "vuex";
import EmbedFormFields from "../formSettings/EmbedFormFields.vue";

export default {
  components: {
    CreateFormFields,
    CreateFormPaymentTab,
    apiParamsTab,
    EmbedFormFields,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formValue: {
      type: Object,
      default: () => null,
    },
  },
  name: "Form",
  data() {
    return {
      loading: false,
      dialog: false,
      tab: "newform",
      showPaymentTab: null,
      showApi: null,
      form: {
        details: null,
        paymentData: {
          bill: [],
        },
        apiData: {
          endpoint: "",
          bill: [],
          bill_type: "standard",
        },
      },
      formId: null,
      field_names: [],
    };
  },
  methods: {
    closeFormDialog() {
      this.dialog = false;
    },

    openDialog() {
      this.dialog = true;
    },

    openFormDialog() {
      this.dialog = true;
    },

    switchTab(from) {
      if (from === "details") {
        if (this.form.details?.form_type === "api") {
          this.tab = "apiParams";
          return;
        }

        if (
          this.form.details?.is_payment &&
          this.form.details?.form_type !== "api"
        ) {
          this.tab = "payment";
          return;
        }

        if (
          !this.form.details?.is_payment &&
          this.form.details?.form_type === "standard"
        ) {
          if (this.isEdit) {
            this.saveSetting();
            return;
          }
          this.$router.push("/form/new-form");
          return;
        }
      }

      if (from === "payment" || from === "api") {
        if (this.isEdit) {
          this.saveSetting();
          return;
        }
        this.$router.push("/form/new-form");
        return;
      }
    },

    saveSetting() {
      if (this.loading) return;
      try {
        this.loading = true;
        this.$store
          .dispatch("formBuilder/updateForm", {
            id: this.formId,
            payload: this.formData,
          })
          .then(() => {
            this.loading = false;
            this.showToast({
              sclass: "success",
              show: true,
              message: "Form updated successfully",
              timeout: 3000,
            });
          });
        this.$emit("updated");
        this.dialog = false;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Error updating form",
          timeout: 3000,
        });
      } finally {
        this.loading = false;
      }
    },

    // triggerSaveAction() {
    //   this.$refs.formFields.dispatchAction();
    // },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formData() {
      const billObj = this.form?.apiData?.bill.map((item) => {
        return {
          label: item.name,
          name: item.name,
          key: item.name.replaceAll(" ", "_"),
          type: "money",
          for: "bill",
          value: item.amount,
        };
      });

      return {
        organization: this.user?.organization?.toString(),
        form_title: this.form?.details?.form_title || "",
        form_type: this.form?.details?.form_type || "standard",
        form_description: this.form.details?.form_description,
        allow_files: this.form.details?.allow_files || false,
        is_private: this.form?.details?.is_private || false,
        is_payment: this.form?.details?.is_payment || false,
        has_signature: this.form?.details?.has_signature || false,
        ...(this.form?.details?.is_payment &&
          this.form?.details?.form_type !== "api" && {
            bill: this.form?.paymentData?.bill,
          }),

        ...(this.form?.details?.form_type === "api" && {
          endpoint: { endpoint: this.form?.apiData?.endpoint },
          bill: this.form?.apiData?.bill.map((item) => {
            return {
              ...item,
              amount: item.amount.replace(/,/g, ""),
            };
          }),
          bill_type: this.form?.apiData?.bill_type,
          // unique array by key from this.field_names and billObj
          field_names: [
            ...new Map(
              [...this.field_names, ...billObj].map((item) => [
                item["key"],
                item,
              ])
            ).values(),
          ],
        }),
      };
    },
  },
  watch: {
    formData: {
      handler() {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(this.formData, null, 2));
        }

        // show payment tab
        if (
          this.form?.details?.is_payment &&
          this.form?.details?.form_type === "standard" &&
          this.form?.details?.form_title &&
          this.form?.details?.form_description
        ) {
          this.showPaymentTab = true;
        } else {
          this.showPaymentTab = false;
        }

        // show api tab
        if (
          this.form?.details?.form_type === "api" &&
          this.form?.details?.form_title &&
          this.form?.details?.form_description
        ) {
          this.showApi = true;
        } else {
          this.showApi = false;
        }

        sessionStorage.setItem("formDetails", JSON.stringify(this.formData));
      },
      deep: true,
      immediate: true,
    },

    formValue: {
      handler(data) {
        if (data !== null) {
          const form = data.data;
          this.formId = data.id;
          // console.log("oepnnnn", JSON.stringify(data, null, 2));
          this.form.details = {
            hypn_id: form.hypn_id,
            form_title: form.form_title,
            form_description: form.form_description,
            form_type: form.form_type,
            is_payment: form.is_payment,
            allow_files: form.allow_files,
            is_private: form.is_private,
            has_signature: form.has_signature,
          };
          this.field_names = form.field_names.map((item) => {
            return {
              ...item,
              name: item.label,
            };
          });

          if (form.is_payment) {
            this.form.paymentData = {
              bill: form.bill[0],
            };
          }

          if (form.form_type === "api") {
            this.form.apiData = {
              endpoint: form.endpoint.endpoint,
              bill: form.bill,
              bill_type: form?.bill_type,
            };
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.modal__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}
.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
.v-input .v-label {
  font-size: 12px !important;
}
.field__title {
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}
.form__field {
  margin-top: 12px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 30px;
  /* background: #ffffff; */
  /* border: 1px solid rgba(212, 216, 223, 0.377431); */
  border-radius: 3px;
}

.btn-text {
  padding-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
  color: #ffffff;
}
@media only screen and (min-width: 600px) {
  /* For tablets: */
  .form__field {
    margin-top: 12px;
    margin-left: 67px;
    margin-right: 67px;
    margin-bottom: 30px;
    /* background: #ffffff; */
    /* border: 1px solid rgba(212, 216, 223, 0.377431); */
    border-radius: 3px;
  }
  .field__title {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 67px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: #7f919b;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .form__field {
    margin-top: 12px;
    margin-left: 67px;
    margin-right: 67px;
    margin-bottom: 30px;
    /* background: #ffffff; */
    /* border: 1px solid rgba(212, 216, 223, 0.377431); */
    border-radius: 3px;
  }
  .field__title {
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 67px;
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.45px;
    color: #7f919b;
  }
}
</style>
