<template>
  <div>
    <v-form ref="form">
      <div class="px-14">
        <v-row>
          <v-col>
            <p class="form-label py-0 mb-2">Product Name</p>
            <v-text-field
              placeholder=""
              outlined
              label=""
              hide-details="auto"
              background-color="white"
              v-model="payment.name"
              :rules="[
                (v) => !!v || 'Product name is required',
                (v) =>
                  v.length <= 300 || 'name must be less than 300 characters',
              ]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="form-label py-0 mb-2">Due Date</p>
            <v-menu
              ><template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details="auto"
                  background-color="white"
                  label=""
                  append-icon="mdi-calendar-blank-outline"
                  outlined
                  :rules="[(v) => !!v || 'Due Date is required']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="dueDateIndays"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="due_date"
                no-title
                scrollable
                :min="minDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <p class="form-label py-0 mb-2">Amount to collect</p>
            <v-text-field
              outlined
              label=""
              @blur="formatAmount"
              hide-details="auto"
              background-color="white"
              v-model="payment.amount"
              :rules="amountRule"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <div class="mt-6">
          <v-textarea
            outlined
            background-color="white"
            hide-details="auto"
            placeholder="payment description"
            v-model="payment.description"
            :rules="[
              (v) => !!v || 'Payment description is required',
              (v) =>
                v.length <= 600 ||
                'Form description must be less than 600 characters',
            ]"
          >
          </v-textarea>
        </div>
        <div id="switch__sect" class="d-flex align-center">
          <v-switch
            dense
            true-value="1"
            false-value="0"
            hide-details="auto"
            v-model="payment.tax"
            label="Include VAT"
          ></v-switch>
          <!-- <v-checkbox
            v-if="$vuetify.breakpoint.smAndDown"
            dense
            v-model="payment.tax"
            label="Include VAT"
            @change="changeValue"
            hide-details="auto"
            class="ml-4"
          ></v-checkbox> -->
        </div>
      </div>
      <DialogActionBtns
        class="pr-0"
        @clickedCancel="$emit('cancel')"
        @click="savePayment"
        btnText="save"
        :loading="loading"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogActionBtns from "../../../components/dialogActionBtns.vue";
export default {
  name: "createFormPaymentTab",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapGetters({
      singleForm: "formBuilder/getSingleForm",
      FormDetails: "formBuilder/getFormDetails",
    }),

    //     var given = moment(“2018-03-10”, “YYYY-MM-DD”);
    // var current = moment(). startOf(‘day’);
    // moment. duration(given. diff(current)). asDays();

    //get the difference between two dates in days using moment.js
    dueDateIndays() {
      if (!this.due_date) return "";
      let given = this.$moment(this.due_date, "YYYY-MM-DD");
      let current = this.$moment().startOf("day");
      return this.$moment.duration(given.diff(current)).asDays();
    },
  },
  data() {
    return {
      loading: false,
      minDate: new Date().toISOString().slice(0, 10),
      amountRule: [
        (value) => !!value || "Amount due is Required.",
        // (v) =>
        //   Number.isInteger(Number(v)) || "The value must be an integer number",
        // (v) => v > 0 || "The value must be a positive number",
      ],

      payment: {
        name: "",
        tax: "0",
        amount: "",
        description: "",
      },
      bill: [],
      due_date: "",
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    saveSetting() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let storeBill = this.bill;
        storeBill.push(this.payment);

        localStorage.setItem("bill", JSON.stringify(storeBill));
        try {
          this.$store.dispatch(
            "formBuilder/savePaymentDetailsToStore",
            storeBill
          );

          let formDetails = JSON.parse(localStorage.getItem("formDetails"));
          let formName = formDetails.formName;
          this.$router.push({
            name: "Create-form",
            query: {
              data: formName.replaceAll(" ", "_"),
              payment: formDetails.payment,
              signature: formDetails.signature,
            },
          });
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
        // console.log(payload);
        this.loading = false;

        this.$emit("cancel");
      }
    },

    savePayment() {
      if (this.$refs.form.validate()) {
        this.$emit("next", "payment");
      }
    },

    formatAmount() {
      let amount = this.payment.amount;
      amount = amount.replace(/,/g, "");

      if (isNaN(parseFloat(amount))) {
        this.payment.amount = "";
        return;
      }

      if (amount) {
        amount = this.addKobo(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.payment.amount = amount;
      }
    },

    addKobo(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (target.endsWith(".")) {
        const newV = target + "00";
        val = newV.trim();
      } else if (target.indexOf(".") == -1) {
        const newV = target + ".00";
        val = newV.trim();
      } else {
        // already has dot
        const newV = parseFloat(target).toFixed(2);
        val = newV.trim();
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (dotCount > 1) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },
  },

  watch: {
    value: {
      handler(val) {
        if (JSON.stringify(val?.bill) !== JSON.stringify(this.payment) && val) {
          this.payment = {
            ...val?.bill,
            amount: this.addKobo(val?.bill?.amount).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
          };

          this.due_date = val?.bill?.date;
        }
      },
      deep: true,
      immediate: true,
    },

    payment: {
      handler() {
        this.$emit("input", {
          bill: [
            {
              ...this.payment,
              date: this.due_date,
              amount: this.payment.amount.replace(/,/g, ""),
            },
          ],
        });
      },
      deep: true,
      immediate: true,
    },
  },

  components: { DialogActionBtns },
  mounted() {
    //
  },
};
</script>

<style scoped>
.form-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(25, 40, 61, 0.8);
}
.theme--light.v-input--switch .v-input--switch__thumb {
  color: #8f96a1 !important;
}
.v-text-field--outlined >>> fieldset {
  top: 0px !important;
}
.v-application--is-ltr .v-text-field--outlined >>> legend {
  text-align: left !important;
  display: none !important;
}
</style>
