<template>
  <v-card-actions class="d-flex justify-center justify-md-end mr-md-14">
    <v-btn
      @click="$emit('clickedCancel')"
      dark
      width="121"
      height="45"
      color="#F4F5F6"
      elevation="0"
      class="text-capitalize mt-4 mb-10 mr-5"
    >
      <v-icon small color="primary">mdi-close</v-icon>
      <span class="primary--text">Cancel</span>
    </v-btn>
    <v-btn
      link
      @click="$emit('click')"
      dark
      :loading="loading"
      :disabled="disabled"
      width="121"
      height="45"
      color="primary"
      class="text-capitalize mt-4 mb-10"
    >
      <v-icon>mdi-chevron-right</v-icon>
      {{ btnText }}
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "dialogActionBtns",
  props: {
    btnText: {
      type: String,
      default: "next",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled {
  color: rgb(255 255 255) !important;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(223 213 213 / 28%) !important;
}
</style>
