<template>
  <div>
    <v-form ref="form">
      <div class="px-14">
        <v-row>
          <v-col>
            <p class="title-label py-0 mb-2">Send Data to</p>
            <p class="form-label py-0 mb-2">
              Specify the URL/Address where you would like the form submissions
              to be posted below.
            </p>
            <v-text-field
              placeholder="Enter URL"
              outlined
              hide-details="auto"
              background-color="white"
              append-icon="mdi-content-copy"
              @click:append="copyUrl"
              v-model="form.endpoint"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div class="mt-2">
          <v-row>
            <v-col class="pr-0">
              <label class="form-label">Item (required)</label>
            </v-col>
            <v-col class="pl-0">
              <label class="form-label">Value</label>
            </v-col>
          </v-row>
          <div>
            <div
              class="d-flex align-center py-1"
              v-for="(item, index) in form.items"
              :key="index"
            >
              <v-text-field
                placeholder="Item name"
                outlined
                hide-details="auto"
                background-color="white"
                v-model="form.items[index].name"
              >
              </v-text-field>
              <v-text-field
                outlined
                :disabled="!allowVariableAmount"
                hide-details="auto"
                placeholder="0"
                background-color="white"
                @blur="formatAmount(index)"
                class="pr-2 pl-2"
                v-model="form.items[index].amount"
              >
              </v-text-field>

              <v-btn small icon @click="removeFieldRow(index)"
                ><v-icon small color="red">mdi-close</v-icon></v-btn
              >
            </div>

            <p
              class="addRow_txt d-inline-block pt-1 m-0"
              @click="addNewFormRow"
            >
              + add new item
            </p>
            <div class="d-flex flex-wrap flex-md-nowrap align-center">
              <v-switch
                hide-details="auto"
                class=""
                color="#2BD5AE"
                disabled
                v-model="payment"
              >
                <template v-slot:label>
                  <span class="text-body-2">Take Payment</span>
                </template>
              </v-switch>
              <v-switch
                hide-details="auto"
                class="ml-md-4"
                color="#2BD5AE"
                v-model="allowVariableAmount"
              >
                <template v-slot:label>
                  <span class="text-body-2">Allow variable value</span>
                </template>
              </v-switch>
            </div>
          </div>
        </div>
      </div>
      <DialogActionBtns
        :disabled="!canSave"
        class="pr-0"
        @clickedCancel="$emit('cancel')"
        @click="saveSetting"
        btnText="save"
        :loading="loading"
      />
    </v-form>

    <v-dialog
      v-model="resetAttrDialog"
      max-width="550px"
      transition="dialog-transition"
    >
      <div class="delete">
        <div class="delete__header">
          <span class="t">Variable Values</span>
          <!-- <v-btn @click="resetAttrDialog = false" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </div>
        <div class="delete__content">
          <span class="msg"
            >Are you sure all variable values will be emptied</span
          >

          <v-btn @click="denyReset" color="primary" large outlined>No </v-btn>

          <v-btn
            class="ml-2"
            color="primary"
            @click="confirmReset"
            elevation="1"
            large
          >
            Yes
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogActionBtns from "@/components/dialogActionBtns.vue";
// import transactionCardsVue from '../../../pages/transactions/components/transactionCards.vue';

export default {
  name: "apiParams",
  props: {
    value: {
      type: Object,
      default: () => ({
        endpoint: "",
        items: [
          {
            name: "",
            amount: "",
          },
        ],
      }),
    },
  },
  data() {
    return {
      loading: false,
      resetAttrDialog: false,
      form: {
        endpoint: "",
        items: [
          {
            name: "",
            amount: "",
          },
        ],
        bill_type: "standard",
      },
    };
  },
  computed: {
    ...mapGetters({
      //api_url: "formBuilder/getApiUrl",
      // variableAmount: "formBuilder/getVariableAmount",
      payment: "formBuilder/TakePaymentStatus",
      // form.items: "formBuilder/getform.itemsArray",
      formDetails: "formBuilder/getFormDetails",
    }),

    canSave() {
      return (
        this.form.items.every((item) => item.name) && this.form.endpoint !== ""
      );
    },

    allowVariableAmount: {
      get: function () {
        return this.form.bill_type === "variable";
      },
      set: function (newValue) {
        // if atleast one item has a value, then we can't enable variable amount
        if (this.form.items.some((item) => item.amount) && !newValue) {
          this.resetAttrDialog = true;
          return;
          // if (
          //   window.prompt("Are you sure all values will  be emptied", "yes") ==
          //   "yes"
          // ) {
          //   // empty all the amounts in the form
          //   this.form.items.forEach((item, index) => {
          //     this.form.items[index].amount = "";
          //   });
          //   return this.$store.dispatch("formBuilder/enableVariable", newValue);
          // } else {
          //   return this.$store.dispatch("formBuilder/enableVariable", false);
          // }
        }

        return (this.form.bill_type = newValue ? "variable" : "standard");
      },
    },
  },
  methods: {
    // map Actions
    ...mapActions({ showToast: "ui/showToast" }),

    confirmReset() {
      this.form.items.forEach((item, index) => {
        this.form.items[index].amount = "";
      });
      this.resetAttrDialog = false;
      this.form.bill_type = "standard";
    },

    denyReset() {
      this.resetAttrDialog = false;
      this.$nextTick(() => {
        this.form.bill_type = "variable";
        this.allowVariableAmount = true;
      });
    },

    formatAmount(index) {
      let amount = this.form.items[index].amount;
      amount = amount.replace(/,/g, "");

      if (isNaN(parseFloat(amount))) {
        this.form.items[index].amount = "";
        return;
      }

      if (amount) {
        amount = this.addKobo(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.form.items[index].amount = amount;
      }
    },

    addKobo(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (target.endsWith(".")) {
        const newV = target + "00";
        val = newV.trim();
      } else if (target.indexOf(".") == -1) {
        const newV = target + ".00";
        val = newV.trim();
      } else {
        // already has dot
        const newV = parseFloat(target).toFixed(2);
        val = newV.trim();
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (dotCount > 1) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },

    //copy the api url
    copyUrl() {
      this.$clipboard(this.form.endpoint).then(
        this.showToast({
          sclass: "success",
          show: true,
          message: `copied url to clipboard`,
          timeout: 3000,
        })
      );
    },

    //add a new row of Inputs field
    addNewFormRow() {
      this.form.items.push({
        name: "",
        amount: "",
      });
    },
    removeFieldRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      } else {
        this.form.items[index].name = "";
        this.form.items[index].amount = "";
      }
    },

    saveSetting() {
      this.$emit("next", "api");
      // let formDetails = JSON.parse(localStorage.getItem("formDetails"));
      // let formName = formDetails.formName;
      // this.$store.dispatch(
      //   "formBuilder/updateform.items",
      //   this.form.items.map((item) => {
      //     return {
      //       name: item.name,
      //       amount: item.amount.replace(/,/g, ""),
      //     };
      //   })
      // );
      // this.$router.push({
      //   name: "Create-form",
      //   query: {
      //     data: formName.replaceAll(" ", "_"),
      //     type: formDetails.formType,
      //   },
      // });
    },
  },
  components: { DialogActionBtns },

  watch: {
    form: {
      handler(val) {
        this.$emit("input", {
          endpoint: val.endpoint,
          bill: val.items,
          bill_type: val.bill_type,
        });
      },
      deep: true,
      immediate: true,
    },

    value: {
      handler(val) {
        // console.log("value", JSON.stringify(val, null, 2));

        if (
          JSON.stringify(val?.bill) !== JSON.stringify(this.form?.items) &&
          val?.bill?.length > 0
        ) {
          this.form.items = val?.bill.map((item) => {
            return {
              ...item,
              amount: this.addKobo(item.amount).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
            };
          });
        }

        if (val?.endpoint !== this.form?.endpoint) {
          this.form.endpoint = val?.endpoint;
        }

        if (val?.bill_type !== this.form?.bill_type) {
          this.form.bill_type = val?.bill_type;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}
.title-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #19283d;
}
.addRow_txt {
  color: rgba(25, 40, 61, 0.8);
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  margin-top: 10px;
  text-align: start;
  text-decoration: underline;
}
.theme--light.v-input--switch .v-input--switch__thumb {
  color: #8f96a1 !important;
}
.v-text-field--outlined >>> fieldset {
  top: 0px !important;
}
.v-text-field--outlined legend {
  line-height: 0px !important;
  padding: 0 !important;
}
.v-application--is-ltr .v-text-field--outlined >>> legend {
  text-align: left !important;
  display: none !important;
}

.delete {
  border-radius: 8px;
  background-color: #fff;

  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__content {
    background-color: #fefcf8;
    padding: 30px 50px;
    text-align: center;

    .msg {
      font-size: 16px;
      color: #757575;
      line-height: 24px;
      display: block;
      margin-bottom: 30px;
    }

    #add-to-draft {
      display: block;
      margin: 50px auto 0px auto;
      background: transparent;
      color: #d7a47b;
      cursor: pointer;
      font-size: 17px;
      border-bottom: 1px solid #d7a47b;
    }
  }
}
</style>
