var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form"},[_c('div',{staticClass:"px-14"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Form Name")]),_c('v-text-field',{attrs:{"placeholder":"","outlined":"","label":"","hide-details":"auto","background-color":"white","rules":[
              function (v) { return !!v || 'Form name is required'; },
              function (v) { return (v && v.length <= 300) ||
                'Form name must be less than 300 characters'; } ]},model:{value:(_vm.form.form_title),callback:function ($$v) {_vm.$set(_vm.form, "form_title", $$v)},expression:"form.form_title"}})],1)],1),_c('div',{staticClass:"d-flex flex-wrap flex-md-nowrap align-center",attrs:{"id":"switch__sect"}},[_c('v-switch',{staticStyle:{"font-size":"12px"},attrs:{"dense":"","disabled":_vm.disablePayment,"hide-details":"auto"},on:{"change":_vm.revertBack},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-body-2"},[_vm._v("Take Payment")])]},proxy:true}]),model:{value:(_vm.form.is_payment),callback:function ($$v) {_vm.$set(_vm.form, "is_payment", $$v)},expression:"form.is_payment"}}),_c('v-switch',{staticClass:"ml-md-4",attrs:{"dense":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-body-2"},[_vm._v("Allow Files")])]},proxy:true}]),model:{value:(_vm.form.allow_files),callback:function ($$v) {_vm.$set(_vm.form, "allow_files", $$v)},expression:"form.allow_files"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-textarea',{attrs:{"outlined":"","label":"","background-color":"white","hide-details":"auto","placeholder":"form description","rules":[
            function (v) { return !!v || 'Form description is required'; },
            function (v) { return (v && v.length <= 600) ||
              'Form description must be less than 600 characters'; } ]},model:{value:(_vm.form.form_description),callback:function ($$v) {_vm.$set(_vm.form, "form_description", $$v)},expression:"form.form_description"}})],1),_c('br'),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"pr-2"},[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Form Type")]),_c('v-select',{attrs:{"outlined":"","label":"","disabled":_vm.isEdit,"background-color":"white","hide-details":"auto","items":_vm.formType,"item-text":"text","item-value":"value"},model:{value:(_vm.form.form_type),callback:function ($$v) {_vm.$set(_vm.form, "form_type", $$v)},expression:"form.form_type"}})],1),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"form-label py-0 mb-2"},[_vm._v("Assignee")]),_c('v-select',{attrs:{"outlined":"","label":"","disabled":_vm.isEdit,"background-color":"white","hide-details":"auto","items":_vm.assigneeType,"item-text":"text","item-value":"value"},model:{value:(_vm.form.assignee),callback:function ($$v) {_vm.$set(_vm.form, "assignee", $$v)},expression:"form.assignee"}})],1)])],1),_c('DialogActionBtns',{staticClass:"pr-0",attrs:{"btnText":_vm.form.form_type === 'api' || _vm.form.is_payment
          ? 'next'
          : _vm.isEdit
          ? 'update'
          : 'create',"loading":_vm.loading},on:{"clickedCancel":function($event){return _vm.$emit('cancel')},"click":_vm.changeTab}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }