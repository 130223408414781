<template>
  <div class="clipcont">
    <h6 class="titleHeader">{{ title }}</h6>
    <p class="info__text mb-2">{{ subTitle }}</p>
    <div class="copyContainer px-5">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "clipboardInput",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },

  methods: {
    //
  },
};
</script>

<style lang="scss" scoped>
.clipcont {
  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #19283d;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.45px;
    color: rgba(25, 40, 61, 0.8);
  }
  .copyContainer {
    width: 480px;
    height: auto;
    background: #ffffff;
    border: 1px solid #f6f3ee;
    border-radius: 8px;
  }
}
</style>
